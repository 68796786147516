import { Typography, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        gap: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
        }
    },

}))

const Rules_TR = ({ location }) => {
    const classes = useStyles()

    return (<Layout nodeLocale='tr-TR' location={location} description='Kullanım Koşulları'>
        <Grid container className={classes.root}>
            <Typography component='h1' variant='h3'>Kullanım Koşulları</Typography>

            <Grid item>
                <Typography variant='body1'>
                    Bu internet sitesinin kullanımı Türkiye Cumhuriyet'i kanunlarına ve işbu kurallara tabidir.
                    Bu internet sitesine girmeniz veya bu internet sitesindeki herhangi bir bilgiyi kullanmanız aşağıdaki koşulları kabul ettiğiniz anlamına gelir.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Bu internet sitesine girilmesi, sitenin ya da sitedeki bilgilerin ve diğer verilerin programların vs. kullanılması sebebiyle, sözleşmenin ihlali, haksız fiil, ya da başkaca sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir zarardan Atlaspet sorumlu değildir. Atlaspet, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul etmez.
                </Typography>
            </Grid>
           <Grid item>
                <Typography variant='body1'>
                    Atlaspet işbu site ve site uzantısında mevcut her tür hizmet, ürün, siteyi kullanma koşulları ile sitede sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, siteyi yeniden organize etme, yayını durdurma hakkını saklı tutar. Değişiklikler sitede yayım anında yürürlüğe girer. Sitenin kullanımı ya da siteye giriş ile bu değişiklikler de kabul edilmiş sayılır. Bu koşullar link verilen diğer web sayfaları için de geçerlidir.
                </Typography>
           </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Atlaspet, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi, bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi, değiştirilmesi veya kullanılması hususunda herhangi bir sorumluluk kabul etmez.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Bu internet sitesi Atlaspet’un kontrolü altında olmayan başka internet sitelerine bağlantı veya referans içerebilir. Atlaspet, bu sitelerin içerikleri veya içerdikleri diğer bağlantılardan sorumlu değildir.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Atlaspet bu internet sitesinin genel görünüm ve dizaynı ile internet sitesindeki tüm bilgi, resim, Atlaspet markası ve diğer markalar, www.atlaspet.com.tr alan adı, logo, ikon, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik veriler, bilgisayar yazılımları, uygulanan satış sistemi, iş metodu ve iş modeli de dahil tüm materyallerin ("Materyaller") ve bunlara ilişkin fikri ve sınai mülkiyet haklarının sahibi veya lisans sahibidir ve yasal koruma altındadır. Internet sitesinde bulunan hiçbir Materyal; önceden izin alınmadan ve kaynak gösterilmeden, kod ve yazılım da dahil olmak üzere, değiştirilemez, kopyalanamaz, çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, başka bir bilgisayara yüklenemez, postalanamaz, iletilemez, sunulamaz ya da dağıtılamaz. Internet sitesinin bütünü veya bir kısmı başka bir internet sitesinde izinsiz olarak kullanılamaz. Aksine hareketler hukuki ve cezai sorumluluğu gerektirir. Atlaspet'un burada açıkça belirtilmeyen diğer tüm hakları saklıdır.
    
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Atlaspet, dilediği zaman bu yasal uyarı sayfasının içeriğini güncelleme yetkisini saklı tutmaktadır ve kullanıcılarına siteye her girişte yasal uyarı sayfasını ziyaret etmelerini tavsiye etmektedir.
    
                </Typography>
            </Grid>





        </Grid>
    </Layout>)
}
export default Rules_TR